import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import { isMobile } from "../utils/media_query";

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    static: Boolean,
    animation: Boolean,
    disableMobileAnimation: Boolean
  }
  static modalOpenClass = "modal-open"

  connect() {
    document.body.classList.add(this.#modalOpenClass)
    if (this.hasModalTarget && !this.staticValue) {
      useClickOutside(this, { element: this.modalTarget })
    }

    if ( this.animationValue ) {
      this.addAppearAnimation()
    }

    this.#hideModalBeforeCache()
  }

  clickOutside(event) {
    event.preventDefault()
    this.close(event)
  }

  close(e) {
    // there is a bug of stimulus keyboard event listener that `esc` event will trigger by stimulus when user choose the option offer by history
    // https://github.com/hotwired/stimulus/issues/743
    if (!(e instanceof CustomEvent) && (this.#isPressEsc(e) || this.#isClickCloseBtn(e))) {
      if (this.animationValue) {
        this.addDisappearAnimation()
        setTimeout(() => this.hideModal(), 250)
      } else {
        this.hideModal()
      }
    }
  }

  submitEnd(e) {
    if (!e.detail.success) return

    if (this.animationValue) {
      this.addDisappearAnimation()
      setTimeout(() => this.hideModal(), 250)
    }
    else {
      this.hideModal()
    }
  }

  submitForm(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.element.querySelector('form').submit();
    }
  }

  hideModal() {
    document.body.classList.remove(this.#modalOpenClass)
    this.element.remove()
  }

  #isPressEsc(e) {
    return (e instanceof KeyboardEvent) && e.key === "Escape"
  }

  // Since this method will only be called by Keyboard event by press esc or Click the close button
  // So it's simply check by Event type
  #isClickCloseBtn(e) {
    return (e instanceof PointerEvent) || (e instanceof MouseEvent)
  }

  #hideModalBeforeCache() {
    document.addEventListener("turbo:before-cache", this.hideModal.bind(this))
  }

  get #modalOpenClass() {
    return "modal-open"
  }

  addAppearAnimation() {
    if ( this.#isMobileAnimationEnabled || !isMobile() ) {
      if ( this.hasModalTarget ) {
        this.modalTarget.classList.add("animation-fade-in-down")
      }
      this.element.classList.add("animation-fade-in")
    }
  }

  addDisappearAnimation() {
    if ( this.#isMobileAnimationEnabled || !isMobile() ) {
      if ( this.hasModalTarget ) {
        this.modalTarget.classList.add("animation-fade-out-up")
      }
      this.element.classList.add("animation-fade-out")
    }
  }

  get #isMobileAnimationEnabled() {
    return isMobile() && !this.disableMobileAnimationValue
  }
}
